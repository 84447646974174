import React from "react";
import { LineHelpers } from "victory-core";
import { useCanvasContext } from "./hooks/use-canvas-context";
export var CanvasCurve = function (props) {
  var _useCanvasContext = useCanvasContext(),
      canvasRef = _useCanvasContext.canvasRef,
      clear = _useCanvasContext.clear,
      clip = _useCanvasContext.clip;

  var style = props.style,
      data = props.data;
  var stroke = style.stroke,
      strokeWidth = style.strokeWidth;
  var draw = React.useCallback(function (ctx) {
    var line = LineHelpers.getLineFunction(props);
    ctx.strokeStyle = stroke;
    ctx.lineWidth = strokeWidth;
    line.context(ctx)(data);
    ctx.stroke();
  }, [data, props, stroke, strokeWidth]);
  React.useEffect(function () {
    var _canvasRef$current;

    var ctx = (_canvasRef$current = canvasRef.current) === null || _canvasRef$current === void 0 ? void 0 : _canvasRef$current.getContext("2d");
    if (!ctx) return;
    clear(ctx);
    draw(ctx);
    clip(ctx);
  }, [canvasRef, draw, clear, clip]);
  return null;
};