import _isNil from "lodash/isNil";
import _values from "lodash/values";

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

import { Helpers, LabelHelpers, Data, Domain, Scale } from "victory-core";
export var getSymbol = function (data, props) {
  if (props.bubbleProperty) {
    return "circle";
  }

  return data.symbol || props.symbol;
};
export var getBubbleSize = function (datum, props) {
  var data = props.data,
      z = props.z,
      maxBubbleSize = props.maxBubbleSize,
      minBubbleSize = props.minBubbleSize;
  var zData = data.map(function (point) {
    return point[z];
  });
  var zMin = Math.min.apply(Math, _toConsumableArray(zData));
  var zMax = Math.max.apply(Math, _toConsumableArray(zData));

  var getMaxRadius = function () {
    var minPadding = Math.min.apply(Math, _toConsumableArray(_values(Helpers.getPadding(props))));
    return Math.max(minPadding, 5); // eslint-disable-line no-magic-numbers
  };

  var maxRadius = maxBubbleSize || getMaxRadius();
  var minRadius = minBubbleSize || maxRadius * 0.1; // eslint-disable-line no-magic-numbers

  if (zMax === zMin) {
    return Math.max(minRadius, 1);
  }

  var maxArea = Math.PI * Math.pow(maxRadius, 2);
  var minArea = Math.PI * Math.pow(minRadius, 2);
  var pointArea = (datum[z] - zMin) / (zMax - zMin) * maxArea;
  var area = Math.max(pointArea, minArea);
  var radius = Math.sqrt(area / Math.PI);
  return Math.max(radius, 1);
};
export var getSize = function (datum, props) {
  var size = props.size,
      z = props.z;

  if (datum.size) {
    return typeof datum.size === "function" ? datum.size : Math.max(datum.size, 1);
  } else if (typeof props.size === "function") {
    return size;
  } else if (datum[z]) {
    return getBubbleSize(datum, props);
  }

  return Math.max(size || 0, 1);
};

var getCalculatedValues = function (props) {
  var defaultStyles = Helpers.getDefaultStyles(props, "scatter");
  var style = Helpers.getStyles(props.style, defaultStyles);
  var range = {
    x: Helpers.getRange(props, "x"),
    y: Helpers.getRange(props, "y")
  };
  var domain = {
    x: Domain.getDomain(props, "x"),
    y: Domain.getDomain(props, "y")
  };
  var scale = {
    x: Scale.getBaseScale(props, "x").domain(domain.x).range(props.horizontal ? range.y : range.x),
    y: Scale.getBaseScale(props, "y").domain(domain.y).range(props.horizontal ? range.x : range.y)
  };
  var origin = props.polar ? props.origin || Helpers.getPolarOrigin(props) : undefined;
  var z = props.bubbleProperty || "z";
  var data = Data.getData(props);
  data = Data.formatDataFromDomain(data, domain);
  return {
    domain: domain,
    data: data,
    scale: scale,
    style: style,
    origin: origin,
    z: z
  };
};

export var getBaseProps = function (initialProps, fallbackProps) {
  var modifiedProps = Helpers.modifyProps(initialProps, fallbackProps, "scatter");
  var props = Object.assign({}, modifiedProps, getCalculatedValues(modifiedProps));
  var data = props.data,
      domain = props.domain,
      events = props.events,
      height = props.height,
      origin = props.origin,
      padding = props.padding,
      polar = props.polar,
      scale = props.scale,
      name = props.name,
      sharedEvents = props.sharedEvents,
      standalone = props.standalone,
      style = props.style,
      theme = props.theme,
      width = props.width,
      labels = props.labels,
      horizontal = props.horizontal,
      disableInlineStyles = props.disableInlineStyles;
  var initialChildProps = {
    parent: {
      style: style.parent,
      scale: scale,
      domain: domain,
      data: data,
      height: height,
      width: width,
      standalone: standalone,
      theme: theme,
      origin: origin,
      polar: polar,
      padding: padding,
      name: name,
      horizontal: horizontal
    }
  };
  return data.reduce(function (childProps, datum, index) {
    var eventKey = !_isNil(datum.eventKey) ? datum.eventKey : index;

    var _Helpers$scalePoint = Helpers.scalePoint(props, datum),
        x = _Helpers$scalePoint.x,
        y = _Helpers$scalePoint.y;

    var dataProps = {
      x: x,
      y: y,
      datum: datum,
      data: data,
      index: index,
      scale: scale,
      polar: polar,
      origin: origin,
      horizontal: horizontal,
      size: getSize(datum, props),
      symbol: getSymbol(datum, props),
      style: disableInlineStyles ? {} : style.data,
      disableInlineStyles: disableInlineStyles
    };
    childProps[eventKey] = {
      data: dataProps
    };
    var text = LabelHelpers.getText(props, datum, index);

    if (text !== undefined && text !== null || labels && (events || sharedEvents)) {
      childProps[eventKey].labels = LabelHelpers.getProps(props, index);
    }

    return childProps;
  }, initialChildProps);
};