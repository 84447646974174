import React from "react";
import { Helpers, PointPathHelpers } from "victory-core";
import { useCanvasContext } from "./hooks/use-canvas-context";

var getPath = function (props) {
  var x = props.x,
      y = props.y,
      size = props.size,
      symbol = props.symbol;

  if (props.getPath) {
    return props.getPath(x, y, size);
  }

  var pathFunctions = {
    circle: PointPathHelpers.circle,
    square: PointPathHelpers.square,
    diamond: PointPathHelpers.diamond,
    triangleDown: PointPathHelpers.triangleDown,
    triangleUp: PointPathHelpers.triangleUp,
    plus: PointPathHelpers.plus,
    minus: PointPathHelpers.minus,
    star: PointPathHelpers.star,
    cross: PointPathHelpers.cross
  };
  var symbolFunction = typeof pathFunctions[symbol] === "function" ? pathFunctions[symbol] : pathFunctions.circle;
  return symbolFunction(x, y, size);
};

var evaluateProps = function (props) {
  /**
   * Potential evaluated props are:
   * `size`
   * `style`
   * `symbol`
   */
  var size = Helpers.evaluateProp(props.size, props);
  var style = Helpers.evaluateStyle(props.style, props);
  var symbol = Helpers.evaluateProp(props.symbol, props);
  return Object.assign({}, props, {
    size: size,
    style: style,
    symbol: symbol
  });
};

export var CanvasPoint = function (props) {
  var _useCanvasContext = useCanvasContext(),
      canvasRef = _useCanvasContext.canvasRef;

  var modifiedProps = evaluateProps(props);
  var draw = React.useCallback(function (ctx) {
    var style = modifiedProps.style;
    var path = getPath(modifiedProps);
    ctx.fillStyle = style.fill; // eslint-disable-next-line no-undef

    var path2d = new Path2D(path);
    ctx.fill(path2d);
  }, [modifiedProps]);
  React.useEffect(function () {
    var _canvasRef$current;

    var ctx = (_canvasRef$current = canvasRef.current) === null || _canvasRef$current === void 0 ? void 0 : _canvasRef$current.getContext("2d");
    if (!ctx) return;
    draw(ctx); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return null;
};