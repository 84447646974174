import _isNil from "lodash/isNil";
import _without from "lodash/without";

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

// victory-vendor note: This module is still CommonJS, so not part of victory-vendor.
import { voronoi as d3Voronoi } from "d3-voronoi";
import { Helpers, LabelHelpers, Scale, Domain, Data } from "victory-core"; // Re-export for tests

export { d3Voronoi as _internalD3Voronoi };

var getVoronoi = function (props, range, scale) {
  var minRange = [Math.min.apply(Math, _toConsumableArray(range.x)), Math.min.apply(Math, _toConsumableArray(range.y))];
  var maxRange = [Math.max.apply(Math, _toConsumableArray(range.x)), Math.max.apply(Math, _toConsumableArray(range.y))];

  var angleAccessor = function (d) {
    var x = scale.x(d._x1 !== undefined ? d._x1 : d._x);
    return -1 * x + Math.PI / 2;
  };

  var xAccessor = function (d) {
    return props.horizontal ? scale.y(d._y1 !== undefined ? d._y1 : d._y) : scale.x(d._x1 !== undefined ? d._x1 : d._x);
  };

  var yAccessor = function (d) {
    return props.horizontal ? scale.x(d._x1 !== undefined ? d._x1 : d._x) : scale.y(d._y1 !== undefined ? d._y1 : d._y);
  };

  return d3Voronoi().x(function (d) {
    return props.polar ? angleAccessor(d) : xAccessor(d);
  }).y(function (d) {
    return yAccessor(d);
  }).extent([minRange, maxRange]);
};

var getCalculatedValues = function (props) {
  var defaultStyles = props.theme && props.theme.voronoi && props.theme.voronoi.style ? props.theme.voronoi.style : {};
  var style = Helpers.getStyles(props.style, defaultStyles);
  var range = {
    x: Helpers.getRange(props, "x"),
    y: Helpers.getRange(props, "y")
  };
  var domain = {
    x: Domain.getDomain(props, "x"),
    y: Domain.getDomain(props, "y")
  };
  var scale = {
    x: Scale.getBaseScale(props, "x").domain(domain.x).range(props.horizontal ? range.y : range.x),
    y: Scale.getBaseScale(props, "y").domain(domain.y).range(props.horizontal ? range.x : range.y)
  };
  var data = Data.getData(props);
  data = Data.formatDataFromDomain(data, domain); // Manually remove data with null _x or _y values.
  // Otherwise, we hit null error in: d3-voronoi/src/Cell.js

  data = data.filter(function (datum) {
    if (datum._x === null) {
      return false;
    }

    if (datum._y === null) {
      return false;
    }

    return true;
  });
  var voronoi = getVoronoi(props, range, scale);
  var polygons = voronoi.polygons(data);
  var origin = props.polar ? props.origin || Helpers.getPolarOrigin(props) : undefined;
  return {
    domain: domain,
    data: data,
    scale: scale,
    style: style,
    polygons: polygons,
    origin: origin
  };
};

export var getBaseProps = function (initialProps, fallbackProps) {
  var modifiedProps = Helpers.modifyProps(initialProps, fallbackProps, "scatter");
  var props = Object.assign({}, modifiedProps, getCalculatedValues(modifiedProps));
  var data = props.data,
      domain = props.domain,
      events = props.events,
      height = props.height,
      origin = props.origin,
      padding = props.padding,
      polar = props.polar,
      polygons = props.polygons,
      scale = props.scale,
      sharedEvents = props.sharedEvents,
      standalone = props.standalone,
      style = props.style,
      theme = props.theme,
      width = props.width,
      labels = props.labels,
      name = props.name;
  var initialChildProps = {
    parent: {
      style: style.parent,
      scale: scale,
      domain: domain,
      data: data,
      standalone: standalone,
      height: height,
      width: width,
      theme: theme,
      origin: origin,
      polar: polar,
      padding: padding,
      name: name
    }
  };
  return data.reduce(function (childProps, datum, index) {
    var polygon = _without(polygons[index], "data");

    var eventKey = !_isNil(datum.eventKey) ? datum.eventKey : index;

    var _Helpers$scalePoint = Helpers.scalePoint(props, datum),
        x = _Helpers$scalePoint.x,
        y = _Helpers$scalePoint.y;

    var dataProps = {
      x: x,
      y: y,
      datum: datum,
      data: data,
      index: index,
      scale: scale,
      polygon: polygon,
      origin: origin,
      size: props.size,
      style: style.data
    };
    childProps[eventKey] = {
      data: dataProps
    };
    var text = LabelHelpers.getText(props, datum, index);

    if (text !== undefined && text !== null || labels && (events || sharedEvents)) {
      childProps[eventKey].labels = LabelHelpers.getProps(props, index);
    }

    return childProps;
  }, initialChildProps);
};