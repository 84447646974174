import React from "react";
import { getBarPath, getBarWidth, getCornerRadius, getPolarBarPath, getStyle } from "victory-bar";
import { useCanvasContext } from "./hooks/use-canvas-context";

var evaluateProps = function (props) {
  /**
   * Potential evaluated props of following must be evaluated in this order:
   * 1) `style`
   * 2) `barWidth`
   * 3) `cornerRadius`
   */
  var style = getStyle(props.style, props);
  var barWidth = getBarWidth(props.barWidth, Object.assign({}, props, {
    style: style
  }));
  var cornerRadius = getCornerRadius(props.cornerRadius, Object.assign({}, props, {
    style: style,
    barWidth: barWidth
  }));
  var modifiedProps = Object.assign({}, props, {
    style: style,
    barWidth: barWidth,
    cornerRadius: cornerRadius
  });
  return modifiedProps;
};

var usePreviousValue = function (value) {
  var ref = React.useRef();
  React.useEffect(function () {
    ref.current = value;
  });
  return ref.current;
};

export var CanvasBar = function (props) {
  var _useCanvasContext = useCanvasContext(),
      canvasRef = _useCanvasContext.canvasRef;

  var modifiedProps = evaluateProps(props);
  var polar = modifiedProps.polar,
      style = modifiedProps.style,
      barWidth = modifiedProps.barWidth,
      cornerRadius = modifiedProps.cornerRadius,
      origin = modifiedProps.origin;
  var path2d = React.useMemo(function () {
    var p = polar ? getPolarBarPath(modifiedProps, cornerRadius) : getBarPath(modifiedProps, barWidth, cornerRadius);
    return new Path2D(p);
  }, [polar, barWidth, cornerRadius, modifiedProps]);
  var previousPath = usePreviousValue(path2d);
  var draw = React.useCallback(function (ctx, path) {
    ctx.fillStyle = style.fill;
    ctx.strokeStyle = style.stroke;
    ctx.globalAlpha = style.fillOpacity;
    ctx.lineWidth = style.strokeWidth;

    if (polar) {
      ctx.translate((origin === null || origin === void 0 ? void 0 : origin.x) || 0, (origin === null || origin === void 0 ? void 0 : origin.y) || 0);
    }

    ctx.fill(path);
    ctx.setTransform(1, 0, 0, 1, 0, 0);
  }, [style, origin, polar]); // This will clear the previous bar without clearing the entire canvas

  var clearPreviousPath = React.useCallback(function (ctx) {
    if (previousPath) {
      ctx.save(); // This ensures that the entire shape is erased

      var strokeWidth = style.strokeWidth || 0;
      ctx.lineWidth = strokeWidth + 2;
      ctx.globalCompositeOperation = "destination-out";
      draw(ctx, previousPath);
      ctx.stroke(previousPath);
      ctx.restore();
    }
  }, [draw, previousPath, style]);
  React.useEffect(function () {
    var _canvasRef$current;

    var ctx = (_canvasRef$current = canvasRef.current) === null || _canvasRef$current === void 0 ? void 0 : _canvasRef$current.getContext("2d");
    if (!ctx) return;
    clearPreviousPath(ctx);
    draw(ctx, path2d);
  }, [canvasRef, draw, polar, barWidth, cornerRadius, modifiedProps, path2d, clearPreviousPath]);
  return null;
};