import React from "react";

export function useKeepAwake() {}

export function activateKeepAwake() {}

export function deactivateKeepAwake() {}

export default function KeepAwake() {
  return null;
}
