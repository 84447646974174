var $jscomp=$jscomp||{};$jscomp.scope={};$jscomp.ASSUME_ES5=!1;$jscomp.ASSUME_NO_NATIVE_MAP=!1;$jscomp.ASSUME_NO_NATIVE_SET=!1;$jscomp.defineProperty=$jscomp.ASSUME_ES5||"function"==typeof Object.defineProperties?Object.defineProperty:function(a,d,b){a!=Array.prototype&&a!=Object.prototype&&(a[d]=b.value)};$jscomp.getGlobal=function(a){return"undefined"!=typeof window&&window===a?a:"undefined"!=typeof global&&null!=global?global:a};$jscomp.global=$jscomp.getGlobal(this);$jscomp.SYMBOL_PREFIX="jscomp_symbol_";
$jscomp.initSymbol=function(){$jscomp.initSymbol=function(){};$jscomp.global.Symbol||($jscomp.global.Symbol=$jscomp.Symbol)};$jscomp.symbolCounter_=0;$jscomp.Symbol=function(a){return $jscomp.SYMBOL_PREFIX+(a||"")+$jscomp.symbolCounter_++};
$jscomp.initSymbolIterator=function(){$jscomp.initSymbol();var a=$jscomp.global.Symbol.iterator;a||(a=$jscomp.global.Symbol.iterator=$jscomp.global.Symbol("iterator"));"function"!=typeof Array.prototype[a]&&$jscomp.defineProperty(Array.prototype,a,{configurable:!0,writable:!0,value:function(){return $jscomp.arrayIterator(this)}});$jscomp.initSymbolIterator=function(){}};$jscomp.arrayIterator=function(a){var d=0;return $jscomp.iteratorPrototype(function(){return d<a.length?{done:!1,value:a[d++]}:{done:!0}})};
$jscomp.iteratorPrototype=function(a){$jscomp.initSymbolIterator();a={next:a};a[$jscomp.global.Symbol.iterator]=function(){return this};return a};$jscomp.makeIterator=function(a){$jscomp.initSymbolIterator();$jscomp.initSymbol();$jscomp.initSymbolIterator();var d=a[Symbol.iterator];return d?d.call(a):$jscomp.arrayIterator(a)};
$jscomp.checkStringArgs=function(a,d,b){if(null==a)throw new TypeError("The 'this' value for String.prototype."+b+" must not be null or undefined");if(d instanceof RegExp)throw new TypeError("First argument to String.prototype."+b+" must not be a regular expression");return a+""};
$jscomp.polyfill=function(a,d,b,c){if(d){b=$jscomp.global;a=a.split(".");for(c=0;c<a.length-1;c++){var e=a[c];e in b||(b[e]={});b=b[e]}a=a[a.length-1];c=b[a];d=d(c);d!=c&&null!=d&&$jscomp.defineProperty(b,a,{configurable:!0,writable:!0,value:d})}};
$jscomp.polyfill("String.prototype.startsWith",function(a){return a?a:function(a,b){var c=$jscomp.checkStringArgs(this,a,"startsWith");a+="";var d=c.length,f=a.length;b=Math.max(0,Math.min(b|0,c.length));for(var h=0;h<f&&b<d;)if(c[b++]!=a[h++])return!1;return h>=f}},"es6-impl","es3");
$jscomp.polyfill("Array.from",function(a){return a?a:function(a,b,c){$jscomp.initSymbolIterator();b=null!=b?b:function(a){return a};var d=[],f=a[Symbol.iterator];if("function"==typeof f)for(a=f.call(a);!(f=a.next()).done;)d.push(b.call(c,f.value));else for(var f=a.length,h=0;h<f;h++)d.push(b.call(c,a[h]));return d}},"es6-impl","es3");
$jscomp.iteratorFromArray=function(a,d){$jscomp.initSymbolIterator();a instanceof String&&(a+="");var b=0,c={next:function(){if(b<a.length){var e=b++;return{value:d(e,a[e]),done:!1}}c.next=function(){return{done:!0,value:void 0}};return c.next()}};c[Symbol.iterator]=function(){return c};return c};$jscomp.polyfill("Array.prototype.entries",function(a){return a?a:function(){return $jscomp.iteratorFromArray(this,function(a,b){return[a,b]})}},"es6-impl","es3");
$jscomp.owns=function(a,d){return Object.prototype.hasOwnProperty.call(a,d)};
$jscomp.polyfill("WeakMap",function(a){function d(a){$jscomp.owns(a,c)||$jscomp.defineProperty(a,c,{value:{}})}function b(a){var b=Object[a];b&&(Object[a]=function(a){d(a);return b(a)})}if(function(){if(!a||!Object.seal)return!1;try{var b=Object.seal({}),n=Object.seal({}),c=new a([[b,2],[n,3]]);if(2!=c.get(b)||3!=c.get(n))return!1;c.delete(b);c.set(n,4);return!c.has(b)&&4==c.get(n)}catch(r){return!1}}())return a;var c="$jscomp_hidden_"+Math.random().toString().substring(2);b("freeze");b("preventExtensions");
b("seal");var e=0,f=function(a){this.id_=(e+=Math.random()+1).toString();if(a){$jscomp.initSymbol();$jscomp.initSymbolIterator();a=$jscomp.makeIterator(a);for(var b;!(b=a.next()).done;)b=b.value,this.set(b[0],b[1])}};f.prototype.set=function(a,b){d(a);if(!$jscomp.owns(a,c))throw Error("WeakMap key fail: "+a);a[c][this.id_]=b;return this};f.prototype.get=function(a){return $jscomp.owns(a,c)?a[c][this.id_]:void 0};f.prototype.has=function(a){return $jscomp.owns(a,c)&&$jscomp.owns(a[c],this.id_)};f.prototype.delete=
function(a){return $jscomp.owns(a,c)&&$jscomp.owns(a[c],this.id_)?delete a[c][this.id_]:!1};return f},"es6-impl","es3");$jscomp.MapEntry=function(){};
$jscomp.polyfill("Map",function(a){if(!$jscomp.ASSUME_NO_NATIVE_MAP&&function(){if(!a||!a.prototype.entries||"function"!=typeof Object.seal)return!1;try{var b=Object.seal({x:4}),c=new a($jscomp.makeIterator([[b,"s"]]));if("s"!=c.get(b)||1!=c.size||c.get({x:4})||c.set({x:4},"t")!=c||2!=c.size)return!1;var d=c.entries(),e=d.next();if(e.done||e.value[0]!=b||"s"!=e.value[1])return!1;e=d.next();return e.done||4!=e.value[0].x||"t"!=e.value[1]||!d.next().done?!1:!0}catch(t){return!1}}())return a;$jscomp.initSymbol();
$jscomp.initSymbolIterator();var d=new WeakMap,b=function(a){this.data_={};this.head_=f();this.size=0;if(a){a=$jscomp.makeIterator(a);for(var b;!(b=a.next()).done;)b=b.value,this.set(b[0],b[1])}};b.prototype.set=function(a,b){var d=c(this,a);d.list||(d.list=this.data_[d.id]=[]);d.entry?d.entry.value=b:(d.entry={next:this.head_,previous:this.head_.previous,head:this.head_,key:a,value:b},d.list.push(d.entry),this.head_.previous.next=d.entry,this.head_.previous=d.entry,this.size++);return this};b.prototype.delete=
function(a){a=c(this,a);return a.entry&&a.list?(a.list.splice(a.index,1),a.list.length||delete this.data_[a.id],a.entry.previous.next=a.entry.next,a.entry.next.previous=a.entry.previous,a.entry.head=null,this.size--,!0):!1};b.prototype.clear=function(){this.data_={};this.head_=this.head_.previous=f();this.size=0};b.prototype.has=function(a){return!!c(this,a).entry};b.prototype.get=function(a){return(a=c(this,a).entry)&&a.value};b.prototype.entries=function(){return e(this,function(a){return[a.key,
a.value]})};b.prototype.keys=function(){return e(this,function(a){return a.key})};b.prototype.values=function(){return e(this,function(a){return a.value})};b.prototype.forEach=function(a,b){for(var c=this.entries(),d;!(d=c.next()).done;)d=d.value,a.call(b,d[1],d[0],this)};b.prototype[Symbol.iterator]=b.prototype.entries;var c=function(a,b){var c=b&&typeof b;"object"==c||"function"==c?d.has(b)?c=d.get(b):(c=""+ ++h,d.set(b,c)):c="p_"+b;var e=a.data_[c];if(e&&$jscomp.owns(a.data_,c))for(a=0;a<e.length;a++){var f=
e[a];if(b!==b&&f.key!==f.key||b===f.key)return{id:c,list:e,index:a,entry:f}}return{id:c,list:e,index:-1,entry:void 0}},e=function(a,b){var c=a.head_;return $jscomp.iteratorPrototype(function(){if(c){for(;c.head!=a.head_;)c=c.previous;for(;c.next!=c.head;)return c=c.next,{done:!1,value:b(c)};c=null}return{done:!0,value:void 0}})},f=function(){var a={};return a.previous=a.next=a.head=a},h=0;return b},"es6-impl","es3");
$jscomp.polyfill("Object.entries",function(a){return a?a:function(a){var b=[],c;for(c in a)$jscomp.owns(a,c)&&b.push([c,a[c]]);return b}},"es8","es3");
$jscomp.polyfill("Set",function(a){if(!$jscomp.ASSUME_NO_NATIVE_SET&&function(){if(!a||!a.prototype.entries||"function"!=typeof Object.seal)return!1;try{var b=Object.seal({x:4}),c=new a($jscomp.makeIterator([b]));if(!c.has(b)||1!=c.size||c.add(b)!=c||1!=c.size||c.add({x:4})!=c||2!=c.size)return!1;var d=c.entries(),f=d.next();if(f.done||f.value[0]!=b||f.value[1]!=b)return!1;f=d.next();return f.done||f.value[0]==b||4!=f.value[0].x||f.value[1]!=f.value[0]?!1:d.next().done}catch(h){return!1}}())return a;
$jscomp.initSymbol();$jscomp.initSymbolIterator();var d=function(a){this.map_=new Map;if(a){a=$jscomp.makeIterator(a);for(var b;!(b=a.next()).done;)this.add(b.value)}this.size=this.map_.size};d.prototype.add=function(a){this.map_.set(a,a);this.size=this.map_.size;return this};d.prototype.delete=function(a){a=this.map_.delete(a);this.size=this.map_.size;return a};d.prototype.clear=function(){this.map_.clear();this.size=0};d.prototype.has=function(a){return this.map_.has(a)};d.prototype.entries=function(){return this.map_.entries()};
d.prototype.values=function(){return this.map_.values()};d.prototype.keys=d.prototype.values;d.prototype[Symbol.iterator]=d.prototype.values;d.prototype.forEach=function(a,c){var b=this;this.map_.forEach(function(d){return a.call(c,d,d,b)})};return d},"es6-impl","es3");$jscomp.polyfill("Array.prototype.values",function(a){return a?a:function(){return $jscomp.iteratorFromArray(this,function(a,b){return b})}},"es6","es3");
$jscomp.polyfill("Array.prototype.keys",function(a){return a?a:function(){return $jscomp.iteratorFromArray(this,function(a){return a})}},"es6-impl","es3");$jscomp.polyfill("Array.prototype.fill",function(a){return a?a:function(a,b,c){var d=this.length||0;0>b&&(b=Math.max(0,d+b));if(null==c||c>d)c=d;c=Number(c);0>c&&(c=Math.max(0,d+c));for(b=Number(b||0);b<c;b++)this[b]=a;return this}},"es6-impl","es3");
$jscomp.polyfill("String.prototype.repeat",function(a){return a?a:function(a){var b=$jscomp.checkStringArgs(this,null,"repeat");if(0>a||1342177279<a)throw new RangeError("Invalid count value");a|=0;for(var c="";a;)if(a&1&&(c+=b),a>>>=1)b+=b;return c}},"es6-impl","es3");
(function(a){"object"===typeof exports&&"undefined"!==typeof module?module.exports=a():"function"===typeof define&&define.amd?define([],a):("undefined"!==typeof window?window:"undefined"!==typeof global?global:"undefined"!==typeof self?self:this).mudder=a()})(function(){return function(){function a(d,b,c){function e(h,m){if(!b[h]){if(!d[h]){var n="function"==typeof require&&require;if(!m&&n)return n(h,!0);if(f)return f(h,!0);m=Error("Cannot find module '"+h+"'");throw m.code="MODULE_NOT_FOUND",m;
}m=b[h]={exports:{}};d[h][0].call(m.exports,function(a){return e(d[h][1][a]||a)},m,m.exports,a,d,b,c)}return b[h].exports}for(var f="function"==typeof require&&require,h=0;h<c.length;h++)e(c[h]);return e}return a}()({1:[function(a,d,b){function c(a){for(var b=$jscomp.makeIterator(a),g=b.next();!g.done;g=b.next())for(var g=g.value,c=$jscomp.makeIterator(a),d=c.next();!d.done;d=c.next())if(d=d.value,d!==g&&g.startsWith(d))return!1;return!0}function e(a,b){if("undefined"===typeof this)throw new TypeError("constructor called as a function");
if("string"===typeof a)a=a.split("");else if(!Array.isArray(a))throw new TypeError("symbolsArr must be string or array");if("undefined"===typeof b)b=new Map(a.map(function(a,b){return[a,b]}));else if(b instanceof Object&&!(b instanceof Map))b=new Map(Object.entries(b));else if(!(b instanceof Map))throw new TypeError("symbolsMap can be omitted, a Map, or an Object");for(var g=new Set(b.values()),d=0;d<a.length;d++)if(!g.has(d))throw new RangeError(a.length+" symbols given but "+d+" not found in symbol table");
this.num2sym=a;this.sym2num=b;this.maxBase=this.num2sym.length;this.isPrefixCode=c(a)}function f(a,b,c){return a.reduce(function(a,g){g+=a.rem*c;return{res:a.res.concat(Math.floor(g/b)),rem:g%b,den:b}},{res:[],rem:0,den:b})}function h(a,b,c,d,e){d=void 0===d?[]:d;e=void 0===e?0:e;if(a.length!==b.length)throw Error("same length arrays needed");if(0!==d.length&&2!==d.length)throw Error("zero or two remainders expected");a=a.slice();d.length&&(a=a.concat(d[0]),b=b.slice().concat(d[1]));var g=Array(a.length).fill(0),
k=a.length-1;a:for(;0<=k;--k)if(a[k]>=b[k])g[k]=a[k]-b[k];else{if(0===k)throw Error("cannot go negative");for(var l=k-1;0<=l;--l)if(0<a[l]){a[l]--;for(l+=1;l<k;++l)a[l]+=c-1;g[k]=a[k]+(0<d.length&&k===a.length-1?e:c)-b[k];continue a}throw Error("failed to find digit to borrow from");}return d.length?{res:g.slice(0,-1),rem:g[g.length-1],den:e}:{res:g,rem:0,den:e}}function n(a,b,c,d,e){if(a.length!==b.length)throw Error("same length arrays needed");var g=d>=e,k=b.slice();g&&(d-=e);a.reduceRight(function(a,
d,l){a=d+b[l]+g;g=a>=c;k[l]=g?a-c:a},null);return{res:k,carry:g,rem:d,den:e}}function m(a,b,c,d,e){a.length<b.length?a=a.concat(Array(Math.max(0,b.length-a.length)).fill(0)):b.length<a.length&&(b=b.concat(Array(Math.max(0,a.length-b.length)).fill(0)));if(a.length===b.length&&a.every(function(a,c){return a===b[c]}))throw Error("Start and end strings lexicographically inseparable");var g=f(a,e,c),k=f(b,e,c);a=h(a,g.res,c,[0,g.rem],e);for(var l=k,u=[],m=1;m<=d;++m){var x=n(a.res,l.res,c,a.rem+l.rem,
e);u.push(x);a=h(a.res,g.res,c,[a.rem,g.rem],e);l=n(l.res,k.res,c,l.rem+k.rem,e)}return u}function r(a,b){for(var c=0;c<b.length;c++)if(b[c]&&a[c]!==b[c])return b.slice(0,c+1);return b}function w(a,b){for(var c=Math.min(a.length,b.length),d=0;d<c;d++)if(a[d]!==b[d])return a[d]<b[d];return a.length<b.length}function t(a){var b=!w(a[0],a[1]);b&&a.reverse();a=a.slice(1).reduce(function(a,b){return a.concat([r(a[a.length-1],b)])},[a[0]]);b&&a.reverse();return a}function y(a,b){var c=a>b;c&&(b=$jscomp.makeIterator([b,
a]),a=b.next().value,b=b.next().value);return c?[b,a]:[a,b]}function p(a){return Array.from(Array(a),function(a,b){return b})}function v(a,b){return Array.from(Array(a.length),function(c,d){return[a[d],b[d]]})}c=function(a){a=Array.from(a).sort();for(var b=$jscomp.makeIterator(a.entries()),c=b.next();!c.done;c=b.next()){var d=$jscomp.makeIterator(c.value),c=d.next().value,d=d.next().value,c=a[c-1];if(c!==d&&d.startsWith(c))return!1}return!0};e.prototype.numberToDigits=function(a,b){b=b||this.maxBase;
for(var c=[];1<=a;)c.push(a%b),a=Math.floor(a/b);return c.length?c.reverse():[0]};e.prototype.digitsToString=function(a){var b=this;return a.map(function(a){return b.num2sym[a]}).join("")};e.prototype.stringToDigits=function(a){var b=this;if(!this.isPrefixCode&&"string"===typeof a)throw new TypeError("parsing string without prefix code is unsupported. Pass in array of stringy symbols?");if("string"===typeof a){var c=new RegExp("("+Array.from(this.sym2num.keys()).join("|")+")","g");a=a.match(c)}return a.map(function(a){return b.sym2num.get(a)})};
e.prototype.digitsToNumber=function(a,b){b=b||this.maxBase;var c=1;return a.reduceRight(function(a,d){a+=d*c;c*=b;return a},0)};e.prototype.numberToString=function(a,b){return this.digitsToString(this.numberToDigits(a,b))};e.prototype.stringToNumber=function(a,b){return this.digitsToNumber(this.stringToDigits(a),b)};e.prototype.roundFraction=function(a,b,c){c=c||this.maxBase;var d=Math.ceil(Math.log(b)/Math.log(c));a=this.numberToDigits(Math.round(a/b*Math.pow(c,d)),c);return Array(Math.max(0,d-a.length)).fill(0).concat(a)};
e.prototype.mudder=function(a,b,c,d,e){var f=this;"number"===typeof a&&(c=a,b=a="");a=a||this.num2sym[0];b=b||this.num2sym[this.num2sym.length-1].repeat(a.length+6);c="number"===typeof c?c:1;d=d||this.maxBase;e=e||c+1;b=$jscomp.makeIterator(y(a,b));a=b.next().value;b=b.next().value;a=this.stringToDigits(a,d);b=this.stringToDigits(b,d);c=m(a,b,d,c,e).map(function(a){return a.res.concat(f.roundFraction(a.rem,a.den,d))});c.unshift(a);c.push(b);return t(c).slice(1,c.length-1).map(function(a){return f.digitsToString(a)})};
b=function(a,b){return Array.from(Array(b),function(b,c){return String.fromCharCode(a.charCodeAt(0)+c)})};a=new e(b("0",10).concat(b("A",26)).concat(b("a",26)));var q=b("0",10).concat(b("a",26)),z=q.concat(b("A",26)),A=p(10).concat(p(26).map(function(a){return a+10})).concat(p(26).map(function(a){return a+10})),q=new e(q,new Map(v(z,A)));b=new e(b("a",26),new Map(v(b("a",26).concat(b("A",26)),p(26).concat(p(26)))));d.exports={SymbolTable:e,base62:a,base36:q,alphabet:b,longLinspace:m}},{}]},{},[1])(1)});
